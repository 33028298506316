import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import Layout from "../layout/Layout";
import { toast } from "react-toastify";
import { axiosInstance } from "../config/AxiosInstance";
const Profile = () => {
  let Details = localStorage.getItem("UserDetails");
  Details = JSON.parse(Details);
  const [loader, setLoader] = useState(false);
  const [image, setImage] = useState(null);
  const [blobimage, setBlobimage] = useState(null);

  const validationSchema = Yup.object({
    name: Yup.string().required("User Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });
  const formik = useFormik({
    initialValues: {
      name: Details?.name,
      email: Details?.email,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoader(true);
      const formdata = new FormData();
      formdata.append("name", values.name);
      formdata.append("email", values.email);
      formdata.append("image", image);
      axiosInstance
        .post("profile/update", formdata)
        .then((response) => {
          if (response.data.status) {
            localStorage.setItem(
              "UserDetails",
              JSON.stringify(response.data.data)
            );
            toast.success(response.data.message);
            console.log(response);
          } else {
            toast.error(response.data.message);
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);

          console.log(err);
        });
    },
  });
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    setImage(file);
    // Create a blob URL
    const blobUrl = URL.createObjectURL(file);
    setBlobimage(blobUrl);
  };
  console.log(blobimage);
  return (
    <>
      {loader && <div className="loader" />}
      <Layout>
        <section className="banner-section home-3" style={{ height: "100vh" }}>
          <div className="container">
            <div className="row shadow-lg p-3">
              <div className="col-md-3">
                <img
                  src={
                    blobimage
                      ? blobimage
                      : Details?.image
                      ? `https://api.herbx1.com/uploads/profile/${Details?.image}`
                      : `/assets/images/placeholder.png`
                  }
                  className="img-fluid border w-100 h-100"
                  style={{ borderRadius: "50%" }}
                  alt=""
                />{" "}
              </div>
              <div className="offset-md-1 col-md-7 offset-md-1 p-3">
                <form onSubmit={formik.handleSubmit}>
                  <h5 className="text-white">User Name</h5>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className={`form-control ${
                      formik.touched.name && formik.errors.name
                        ? "is-invalid"
                        : ""
                    }`}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-danger">{formik.errors.name}</div>
                  ) : null}
                  <h5 className="text-white">Email</h5>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className={`form-control ${
                      formik.touched.email && formik.errors.email
                        ? "is-invalid"
                        : ""
                    }`}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-danger">{formik.errors.email}</div>
                  ) : null}
                  <h5 className="text-white">Image</h5>
                  <input
                    type="file"
                    name="image"
                    id="image"
                    className={`form-control`}
                    onChange={handleImageChange}
                  />

                  <div className="float-end">
                    <button className="btn btn-warning">Update</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Profile;
