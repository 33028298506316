import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { axiosInstance } from "../config/AxiosInstance";
import { toast } from "react-toastify";
import Layout from "../layout/Layout";

const Register = () => {
  const navigate = useNavigate("");
  const [loader, setLoader] = useState(false);
  const validationSchema = Yup.object({
    name: Yup.string().required("User Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoader(true);
      const formdata = new FormData();
      formdata.append("name", values.name);
      formdata.append("email", values.email);
      formdata.append("password", values.password);
      axiosInstance
        .post("register", formdata)
        .then((response) => {
          if (response.data.status) {
            navigate("/login");
            toast.success(response.data.message);
            console.log(response);
          } else {
            toast.error(response.data.message);
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);

          console.log(err);
        });
    },
  });
  return (
    <>
    {loader&&<div className="loader"/>}
      <Layout>
        <section className="banner-section home-3" style={{ height: "100vh",padding: '200px 0px 100px 0' }}>
          <div className="container">
            <div className="row">
              <div className="offset-md-3 col-md-6">
                <div className="card shadow-lg bg-transparent">
                  <div className="container">
                    <div className="text-center ">
                      <h3 className="text-white pt-3">Register</h3>
                    </div>
                    <form onSubmit={formik.handleSubmit} className="my-3">
                      <label className="text-white">User Name</label>
                      <input
                        name="name"
                        type="text"
                        placeholder="User Name"
                        className="form-control"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <div className="text-danger">{formik.errors.name}</div>
                      ) : null}
                      <label className="text-white">Email</label>
                      <input
                        name="email"
                        type="email"
                        placeholder="Email"
                        className="form-control"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="text-danger">{formik.errors.email}</div>
                      ) : null}
                      <label className="text-white">Password</label>
                      <input
                        name="password"
                        placeholder="Password"
                        type="password"
                        className="form-control"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                      />
                      {formik.touched.password && formik.errors.password ? (
                        <div className="text-danger">
                          {formik.errors.password}
                        </div>
                      ) : null}

                      <div className="text-center my-3">
                        <button
                          type="submit"
                          className="btn btn-warning text-white w-50"
                        >
                          Sign up
                        </button>
                        <div className="mt-2 text-light">
                          Have An Account?{" "}
                          <Link className="text-white" to={"/login"}>
                            Login Here
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Register;
