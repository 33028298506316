import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { axiosInstance } from "../config/AxiosInstance";
import Swal from "sweetalert2";
const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  // let Details = localStorage.getItem("UserDetails");
  // Details = JSON.parse(Details);
  // const token = localStorage.getItem("Token");
  // const navigate = useNavigate();
  // const handleLogout = () => {
  //   localStorage.clear();
  //   navigate("/login");
  // };

  const CheckLimitOnClick = async () => {
    try {
      const response = await axiosInstance.get("limit/full");
      const { status, message } = response.data;
      console.log(response, "response32");
      if (status) {
        navigate("/covid-conidtion");
      } 
      else {
        Swal.fire({
          icon:'warning',
          iconColor:"#06A813",
          title:message,
          showConfirmButton: true,
          confirmButtonColor:"#06A813",
          timer: 15000
        })
       
      }
    } catch (err) {
      console.log(err);
    }
  };
  const CheckLimitOnLoad = async () => {
    try {
      const response = await axiosInstance.get("limit/full");
      const { status, message } = response.data;
      console.log(status, "response32");
      if (!status && location.pathname !== "/") {
         window.location.href="/"
         setTimeout(() =>{
          Swal.fire({
            icon:'warning',
            iconColor:"#06A813",
            title:message,
            showConfirmButton: true,
            confirmButtonColor:"#06A813",
            
            timer: 15000
          })
         },2000)
         
        
       
      } 
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(()=>{
    CheckLimitOnLoad()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return (
    <>
      {/* desktop menu start here */}
      <header className="header-section transparent-header" id="home">
        <div className="header-area animated fadeInDown menu-fixed">
          <div className="container">
            <div className="primary-menu">
              <div className="logo">
                <Link to="/">
                  <img src="assets/images/logo/03.png" alt="logo" width={199} />
                </Link>
              </div>
              <div className="main-area">
                <div className="main-menu">
                  <ul className="lab-ul">
                    <li>
                      <Link to="/#home">Home</Link>
                    </li>

                    <li>
                      <a href="#faq">Faq</a>
                    </li>

                    <li>
                      <Link to="#contactus">Contact</Link>
                    </li>
                  </ul>
                </div>
                <div className="header-btn d-flex col-x-gap">
                  {/* {!token ? ( */}
                  <div onClick={CheckLimitOnClick}>
                    <Link className="lab-btn style-2">
                      <span>Register Now</span>
                    </Link>
                  </div>

                  <Dropdown
                    isOpen={dropdownOpen}
                    color="primary"
                    toggle={toggle}
                  >
                    <DropdownToggle caret size="lg" className="drop-btn">
                      Administrator
                    </DropdownToggle>
                    <DropdownMenu className="drop-toggle-menu">
                      <DropdownItem
                        tag={Link}
                        to="https://admin.herbx1.com/admin/login"
                      >
                        Admin
                      </DropdownItem>
                      <DropdownItem
                        tag={Link}
                        to="https://admin.herbx1.com/subadmin/login"
                      >
                        Sub Admin
                      </DropdownItem>
                      <DropdownItem
                        tag={Link}
                        to="https://admin.herbx1.com/user/login"
                      >
                        User
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* desktop menu ending here */}
    </>
  );
};

export default Header;
