import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Login from "./auth/Login";
import Register from "./auth/Register";
import Covid from "./components/Forms/Covid";
import Covid2 from "./components/Forms/Covid2";
import Covid3 from "./components/Forms/Covid3";
import { RequireAuth } from "./requirdAuth";
import Profile from "./auth/Profile";
import UserData from "./components/Forms/UserData";
import CovidPositive from "./components/Forms/CovidPositive";
import ExlusionCriteria from "./components/Forms/ExlusionCriteria";
import NotEligible from "./components/Forms/NotEligible";
import MainProvider from "./context/MainProvider";

function App() {
  return (
    <MainProvider>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />

          <Route
            path="/profile"
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          />
          <Route
            path="/user"
            element={
              // <RequireAuth>
                <UserData />
              // </RequireAuth>
            }
          />
          <Route
            path="/covid"
            element={
              // <RequireAuth>
                <Covid />
              // </RequireAuth>
            }
          />
          <Route
            path="/covid2"
            element={
              // <RequireAuth>
                <Covid2 />
              // </RequireAuth>
            }
          />
          <Route
            path="/covid3"
            element={
              // <RequireAuth>
                <Covid3 />
              // </RequireAuth>
            }
          />
            <Route
            path="/covid-conidtion"
            element={
              // <RequireAuth>
                <CovidPositive />
              // </RequireAuth>
            }
          />
            <Route
            path="/exlusion-criteria"
            element={
              // <RequireAuth>
                <ExlusionCriteria />
              // </RequireAuth>
            }
          />
            <Route
            path="/not-eligible"
            element={
              // <RequireAuth>
                <NotEligible />
              // </RequireAuth>
            }
          />
        </Routes>
      </BrowserRouter>
    </MainProvider>
  );
}

export default App;
