import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      {/* Footer Section Start Here */}
      <footer
        style={{ backgroundImage: "url(assets/css/bg-image/background-01.jpg)" }}
      >
        {/* <div className="footer-top padding-tb overlay">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-12">
                <div className="footer-item first-set">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h6>About Covid-19</h6>
                      </div>
                      <div className="content">
                        <p>
                          We believe in Simple Creative and Flexible Design
                          Standards.
                        </p>
                        <h6>Headquarters:</h6>
                        <p>795 Folsom Ave, Suite 600 San Francisco, CA 94107</p>
                        <ul className="lab-ul">
                          <li>
                            <p>
                              <span>Phone:</span>(91) 8547 632521
                            </p>
                          </li>
                          <li>
                            <p>
                              <span>Email:</span>
                              <Link to="#">info@covid-19.com</Link>
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h6>Navigate</h6>
                      </div>
                      <div className="content">
                        <ul className="lab-ul">
                          <li>
                            <Link to="#">
                              <i className="icofont-caret-right" />
                              Advertisers
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icofont-caret-right" />
                              Developers
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icofont-caret-right" />
                              Resources
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icofont-caret-right" />
                              Company
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icofont-caret-right" />
                              Connect
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h6>Social Contact</h6>
                      </div>
                      <div className="content">
                        <ul className="lab-ul">
                          <li>
                            <Link to="#">
                              <i className="icofont-facebook" />
                              Facebook
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icofont-twitter" />
                              Twitter
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icofont-instagram" />
                              Instagram
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icofont-youtube" />
                              YouTube
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icofont-xing" />
                              Xing
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h6>Privacy And Tos</h6>
                      </div>
                      <div className="content">
                        <ul className="lab-ul">
                          <li>
                            <Link to="#">
                              <i className="icofont-caret-right" />
                              Advertiser Agreement
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icofont-caret-right" />
                              Acceptable Use Policy
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icofont-caret-right" />
                              Privacy Policy
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icofont-caret-right" />
                              Technology Privacy
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icofont-caret-right" />
                              Developer Agreement
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="footer-bottom">
          <div className="container">
            <div className="section-wrapper">
              <p>
                © {currentYear} All Rights Reserved. Designed by Your Name
                <Link to="https://hnhtechsolutions.com">HnhTechSolutions</Link>
              </p>
            </div>
          </div>
        </div>
      </footer>
      {/* Footer Section Ending Here */}
    </>
  );
};

export default Footer;
