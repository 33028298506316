
import {  useNavigate } from "react-router-dom";

import Layout from "../../layout/Layout"
import { useFormik } from "formik";

const ExlusionCriteria = () => {
   
    const navigate = useNavigate();
    const criterias=[
        "Be younger than five (5) years of age",
        " Have known allergies or hypersensitivity to any herbal products sold in the US open market.",
        "Concurrently use of prescription medications or herbal supplements",
        "Be severe COVID-19 patients requiring ventilatory support",
        "Be cancer patients or those with organ transplants are ineligible",
        "Have serious heart, liver, kidney or lung conditions are excluded",
        "Be currently participating (or have participated within the past 30 days) in other clinical trials involving investigational drugs or treatments for COVID-19.",
        "Be unable to understand the trial information or provide informed consent.",
        "Have any other condition that, in the judgment of the investigator, would be a contraindication to enrollment or follow-up"
    ]


   
    const formik = useFormik({
        initialValues: {
            criteria:[],
            criteriaNone:"",
            covid_test_date:"",
        },
    
        onSubmit: (values) => {
         
          if(values.criteria.length<=0){
            navigate('/user')
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // You can use 'auto' instead of 'smooth' if you want an instant scroll
              });
          }
          else{
            navigate('/not-eligible')
          }
        },
       
    })
   const criteriaHandler=(data)=>{
    
    if(data.target.checked){
       formik.setValues({
        ...formik.values,
            criteria:[]
       })

    }
   
    formik.handleChange(data)
   }
    return (
        <>
            <Layout>
                <section className="banner-section home-3">
                    <div className="overlay">
                        <div className="container">
                            <div className="row">
                                <div className="m-auto col-md-9">
                                    <div className="card bg-transparent rounded shadow-lg">
                                        <div className="container p-3">
                                            <div className="row  p-2">
                                                <div className="col-md-12 text-center">
                                                        <h3 className="  text-xs">Have One Of Exclusion Criteria</h3>
                                                </div>
                                                <div className="row">
                                                <form onSubmit={formik.handleSubmit} className="criteria-form">
                                           
                                                {criterias.map((data,index)=>(
                                                    <div className="form-check">
                                                    <input
                                                        className="form-check-input px-2 py-2"
                                                        name="criteria"
                                                        type="checkbox"
                                                      
                                                        id={"flexCheckIndeterminate"+index}
                                                        value={data}
                                                        onChange={formik.handleChange}
                                                        checked={formik.values.criteria.some(e=>e===data) ? true : false}
                                                        
                                                        required={formik.values.criteria>0 ? true :false}
                                                    />
                                                    <label className="form-check-label     fs-6" htmlFor={"flexCheckIndeterminate"+index}>
                                                    {data}
                                                    </label>
                                                    </div>
                                                ))}
                                           
                                             <div className="form-check">
                                                <input
                                                    className="form-check-input px-2 py-2"
                                                    name="criteriaNone"
                                                    type="checkbox"
                                                    value="None of These"
                                                     checked={formik.values.criteria.length >0 && formik.values.criteriaNone ? false : null}
                                                    onChange={criteriaHandler}
                                                    required={formik.values.criteria<=0 ? true : false} 
                                                    id="flexCheckIndeterminate"
                                                    
                                                />
                                                <label className="form-check-label     fs-6" htmlFor="flexCheckIndeterminate">None of These
                                                </label>
                                                </div>
                                                <div className="py-2 text-center">
                                                <button 
                                                  type="submit"
                                                     className="btn btn-lg  btn-warning  ">Continue</button> 
                                                </div>
                                             </form>

                                                </div>
                                             
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default ExlusionCriteria