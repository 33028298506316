import React from "react";

const Faq = () => {
  return (
    <>
      {/* faq section start here */}
      <section className="faq-section bg-faq padding-tb " id="faq">
        <div className="faq-shape">
          <img src="assets/images/faq/shape/01.png" alt="action-shape" />
        </div>
        <div className="container">
          <div className="section-header wow fadeInUp" data-wow-delay="0.3s">
            <h2 className="text-light">Friquently Ask Questions</h2>
            <p className="text-light">
              {" "}
              Dynamically formulate fully tested catalysts for change via
              focused methods of empowerment Assertively extend alternative
              synergy and extensive web services.
            </p>
          </div>
          <div className="section-wrapper wow fadeInUp" data-wow-delay="0.4s">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-sm-8 col-12">
                <ul className="accordion lab-ul">
                  <li className="accordion-item bg-warning ">
                    <div className="accordion-list">
                      <div className="left">
                        <div className="icon">
                          <i className="icofont-info" />
                        </div>
                      </div>
                      <div className="right">
                        <h6>What are the objectives of this Website?</h6>
                      </div>
                    </div>
                    <div className="accordion-answer">
                      <p>
                        Perspiciatis unde omnis iste natus error sit voluptatem
                        accusantium doloremque laudantium, totam rem aperiam,
                        eaque ipsa quae ab illo inventore vertatis et quasi
                        archtecto beatae vitae dicta sunt explicab Nemo enim
                        ipsam voluptatem quia voluptas.
                      </p>
                    </div>
                  </li>
                  <li className="accordion-item bg-warning">
                    <div className="accordion-list">
                      <div className="left">
                        <div className="icon">
                          <i className="icofont-info" />
                        </div>
                      </div>
                      <div className="right">
                        <h6>
                          What is the best features and services we deiver?
                        </h6>
                      </div>
                    </div>
                    <div className="accordion-answer">
                      <p>
                        Perspiciatis unde omnis iste natus error sit voluptatem
                        accusantium doloremque laudantium, totam rem aperiam,
                        eaque ipsa quae ab illo inventore vertatis et quasi
                        archtecto beatae vitae dicta sunt explicab Nemo enim
                        ipsam voluptatem quia voluptas.
                      </p>
                    </div>
                  </li>
                  <li className="accordion-item bg-warning">
                    <div className="accordion-list">
                      <div className="left">
                        <div className="icon">
                          <i className="icofont-info" />
                        </div>
                      </div>
                      <div className="right">
                        <h6>Why this Prevention important to me?</h6>
                      </div>
                    </div>
                    <div className="accordion-answer">
                      <p>
                        Perspiciatis unde omnis iste natus error sit voluptatem
                        accusantium doloremque laudantium, totam rem aperiam,
                        eaque ipsa quae ab illo inventore vertatis et quasi
                        archtecto beatae vitae dicta sunt explicab Nemo enim
                        ipsam voluptatem quia voluptas.
                      </p>
                    </div>
                  </li>
                  <li className="accordion-item bg-warning">
                    <div className="accordion-list">
                      <div className="left">
                        <div className="icon">
                          <i className="icofont-info" />
                        </div>
                      </div>
                      <div className="right">
                        <h6>how may I take part in and purchase this?</h6>
                      </div>
                    </div>
                    <div className="accordion-answer">
                      <p>
                        Perspiciatis unde omnis iste natus error sit voluptatem
                        accusantium doloremque laudantium, totam rem aperiam,
                        eaque ipsa quae ab illo inventore vertatis et quasi
                        archtecto beatae vitae dicta sunt explicab Nemo enim
                        ipsam voluptatem quia voluptas.
                      </p>
                    </div>
                  </li>
                  <li className="accordion-item bg-warning">
                    <div className="accordion-list">
                      <div className="left">
                        <div className="icon">
                          <i className="icofont-info" />
                        </div>
                      </div>
                      <div className="right">
                        <h6>What kinds of security policy do you maintain?</h6>
                      </div>
                    </div>
                    <div className="accordion-answer">
                      <p>
                        Perspiciatis unde omnis iste natus error sit voluptatem
                        accusantium doloremque laudantium, totam rem aperiam,
                        eaque ipsa quae ab illo inventore vertatis et quasi
                        archtecto beatae vitae dicta sunt explicab Nemo enim
                        ipsam voluptatem quia voluptas.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 col-sm-8 col-12">
                <ul className="accordion lab-ul">
                  <li className="accordion-item bg-warning">
                    <div className="accordion-list">
                      <div className="left">
                        <div className="icon">
                          <i className="icofont-info" />
                        </div>
                      </div>
                      <div className="right">
                        <h6>Get things done with this beautiful app?</h6>
                      </div>
                    </div>
                    <div className="accordion-answer">
                      <p>
                        Perspiciatis unde omnis iste natus error sit voluptatem
                        accusantium doloremque laudantium, totam rem aperiam,
                        eaque ipsa quae ab illo inventore vertatis et quasi
                        archtecto beatae vitae dicta sunt explicab Nemo enim
                        ipsam voluptatem quia voluptas.
                      </p>
                    </div>
                  </li>
                  <li className="accordion-item bg-warning">
                    <div className="accordion-list">
                      <div className="left">
                        <div className="icon">
                          <i className="icofont-info" />
                        </div>
                      </div>
                      <div className="right">
                        <h6>Starting with Aviki is easier than anything?</h6>
                      </div>
                    </div>
                    <div className="accordion-answer">
                      <p>
                        Perspiciatis unde omnis iste natus error sit voluptatem
                        accusantium doloremque laudantium, totam rem aperiam,
                        eaque ipsa quae ab illo inventore vertatis et quasi
                        archtecto beatae vitae dicta sunt explicab Nemo enim
                        ipsam voluptatem quia voluptas.
                      </p>
                    </div>
                  </li>
                  <li className="accordion-item bg-warning">
                    <div className="accordion-list">
                      <div className="left">
                        <div className="icon">
                          <i className="icofont-info" />
                        </div>
                      </div>
                      <div className="right">
                        <h6>20k+ Customers Love Aviki App?</h6>
                      </div>
                    </div>
                    <div className="accordion-answer">
                      <p>
                        Perspiciatis unde omnis iste natus error sit voluptatem
                        accusantium doloremque laudantium, totam rem aperiam,
                        eaque ipsa quae ab illo inventore vertatis et quasi
                        archtecto beatae vitae dicta sunt explicab Nemo enim
                        ipsam voluptatem quia voluptas.
                      </p>
                    </div>
                  </li>
                  <li className="accordion-item bg-warning">
                    <div className="accordion-list">
                      <div className="left">
                        <div className="icon">
                          <i className="icofont-info" />
                        </div>
                      </div>
                      <div className="right">
                        <h6>Whatever Work You Do You Can Do It In Aviki?</h6>
                      </div>
                    </div>
                    <div className="accordion-answer">
                      <p>
                        Perspiciatis unde omnis iste natus error sit voluptatem
                        accusantium doloremque laudantium, totam rem aperiam,
                        eaque ipsa quae ab illo inventore vertatis et quasi
                        archtecto beatae vitae dicta sunt explicab Nemo enim
                        ipsam voluptatem quia voluptas.
                      </p>
                    </div>
                  </li>
                  <li className="accordion-item bg-warning">
                    <div className="accordion-list">
                      <div className="left">
                        <div className="icon">
                          <i className="icofont-info" />
                        </div>
                      </div>
                      <div className="right">
                        <h6>Download our guide manage your daily works?</h6>
                      </div>
                    </div>
                    <div className="accordion-answer">
                      <p>
                        Perspiciatis unde omnis iste natus error sit voluptatem
                        accusantium doloremque laudantium, totam rem aperiam,
                        eaque ipsa quae ab illo inventore vertatis et quasi
                        archtecto beatae vitae dicta sunt explicab Nemo enim
                        ipsam voluptatem quia voluptas.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* faq section ending here */}
    </>
  );
};

export default Faq;
