import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import HomeBanner from "../components/home/HomeBanner";
// import AboutBanner from "../components/home/AboutBanner";
// import AboutBanner2 from "../components/home/AboutBanner2";
// import ServiceBanner from "../components/home/ServiceBanner";
// import ServiceBanner2 from "../components/home/ServiceBanner2";
// import TeamBanner from "../components/home/TeamBanner";
// import SafeActionBanner from "../components/home/SafeActionBanner";
import Faq from "../components/home/Faq";
// import Blog from "../components/home/Blog";
import Contact from "../components/home/Contact";

const Home = () => {
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setInterval(() => {
      setLoader(false);
    }, 2000);
  }, []);
  return (
    <>
    {loader&&<div className="loader"/>}
      <Layout>
        <HomeBanner />
        {/* <AboutBanner /> */}
        {/* <ServiceBanner /> */}
        {/* <AboutBanner2 /> */}
        {/* <TeamBanner /> */}
        {/* <SafeActionBanner /> */}
        {/* <ServiceBanner2 /> */}
        <Faq />
        {/* <Blog /> */}
        <Contact />
      </Layout>
    </>
  );
};

export default Home;
